<template>
    <KCourse loader-class="MBcont" course-id="4" title="Съемный костюм XIX в." :items="items">
        <div class="MBtextcont">
            <p>Сегодня мы начинаем шить платье. Этот урок будет по полочке. Процесс ответственный, многое нужно учесть. В уроке есть два варианта обработки горловины, с подкладом и без. Пробуйте и выбирайте свой вариант.</p>
            <h3>6.1 Вытачки и плечевой шов</h3>
        </div>

        <VideoView video-id="394b7c9e2c2e440d9ce1441adf8ce183"/>

        <div class="MBtextcont">
            <h3>6.2 Если шьете без подклада</h3>
        </div>

        <VideoView video-id="41606fb89a77497cba2672b60e0bb2a7"/>

        <div class="MBtextcont">
            <h3>6.3 Рукав</h3>
        </div>

        <VideoView video-id="e5924d5e8cde4f7faea5c0e19abd4d07"/>

        <div class="MBtextcont">
            <h3>6.4 Подклад для полочки</h3>
        </div>

        <VideoView video-id="478bc4b7ea6b4af69f6deb055f85f126"/>

    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
        return {
            items: [
                { title: "Подготовка", url:"/mycourses/costume/1"},
                { title: "Снимаем выкройку", url:"/mycourses/costume/2"},
                { title: "Моделируем", url:"/mycourses/costume/3"},
                { title: "Ткань. Раскрой", url:"/mycourses/costume/4"},
                { title: "Нижнее белье", url:"/mycourses/costume/5"},
                { title: "Шьем полочку", url:"/mycourses/costume/6"},
                { title: "Дошиваем платье", url:"/mycourses/costume/7"},
                { title: "Красное платье", url:"/mycourses/costume/8"},
                { title: "Делаем обувь", url:"/mycourses/costume/9"},
                { title: "Ручные швы", url:"/mycourses/costume/10"},
                { title: "Машинные швы", url:"/mycourses/costume/11"},
                { title: "Утюжка", url:"/mycourses/costume/12"},
            ],
        }
        }
    }
</script>